import type { RouterType } from '@gimlite/router';
import { dispatch } from '@gimlite/router';
import {
  Checkbox,
  Color,
  DurationPicker,
  Empty,
  Form,
  Row,
  useRootStore,
} from '@gimlite/watermelon';
import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import {
  Icon,
  IconType,
} from '@gimlite/watermelon/components/icon/icon.component';
import { Image } from '@gimlite/watermelon/components/image/image.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { appService } from '../../../app.machine';
import { Cover } from '../../../components/cover/cover.component';
import './vao.page.scss';

const registeryIconLeft: Record<string, IconType.Config.Type> = {
  MOBILE: 'faMobileScreenSolid',
  FINE: 'customAgent',
  SUBSCRIPTION: 'faSquareParkingSolid',
  SHORT_TERM: 'faSquareParkingSolid',
  OFFSTREET: 'faSquareParkingSolid',
};

type ClaimReason =
  | 'NO-VEHICULE'
  | 'NOT-OWNER'
  | 'TRANSFERRED-VEHICULE'
  | 'USURPATION'
  | 'USER-EXEMPTION'
  | 'PERIOD-EXEMPTION'
  | 'VALID-TICKET'
  | 'VALID-ETICKET'
  | 'WRONG-AMOUNT'
  | 'WRONG-DEDUCTION'
  | 'WRONG-TICKET'
  | 'INVALID-FPS'
  | 'VALID-PREVIOUS-FPS'
  | 'INVALID-PREVIOUS-FPS'
  | '';

type History = {
  tariffType: string;
  startDate: Date;
  endDate: Date;
  usedMeansOfPayment: string;
  upsId: string;
  legacyId: string[];
  status: string | null;
  cancellationReason?: string;
  claimReason?: ClaimReason;
};

export const Vao = observer(({ machine }: RouterType.Legacy) => {
  const { AuthStore, GlobalStore } = useRootStore();
  const me = AuthStore.me as any;

  const { getParamsUrl, setParamsUrl, removeParamsUrl } = useMyUrl();
  const { t, lang }: any = useTranslation();
  const { id } = useParams();
  const searchSessions = useSelector(
    machine,
    ({ context }: any) => context.searchSessions,
  );

  const readSession = useSelector(
    machine,
    ({ context }: any) => context.readSession,
  );

  const updateSession = useSelector(
    machine,
    ({ context }: any) => context.updateSession,
  );

  const upsConfig = useSelector(appService, ({ context }: any) => context.ups);

  const isVerbalizationAllowed = useMemo(
    () =>
      id && upsConfig
        ? !!upsConfig?.[id]?.config?.prestoScan?.isVerbalizationAllowed
        : false,
    [id, upsConfig],
  );

  const historyIconLeft = useCallback(
    ({
      tariffType,
      usedMeansOfPayment,
    }: Pick<
      History,
      'tariffType' | 'usedMeansOfPayment'
    >): IconType.Config.Type => {
      let tariffTypeFormatted = tariffType;

      if (tariffType === 'SHORT_TERM' && usedMeansOfPayment === 'MOBILE') {
        tariffTypeFormatted = 'MOBILE';
      }

      return registeryIconLeft[tariffTypeFormatted];
    },
    [],
  );

  const historyIconRight = useCallback(
    ({
      tariffType,
      startDate,
      endDate,
      upsId,
      currentUpsId,
      sessionStartDate,
      sessionEndDate,
      status,
      claimReason,
      cancellationReason,
    }: Pick<
      History,
      | 'tariffType'
      | 'startDate'
      | 'endDate'
      | 'upsId'
      | 'status'
      | 'claimReason'
      | 'cancellationReason'
    > & {
      currentUpsId: string;
      sessionStartDate: Date;
      sessionEndDate: Date;
    }): {
      icon: IconType.Config.Type;
      color: Extract<
        Color,
        'error' | 'primary' | 'default' | 'warn' | 'disabled'
      >;
      tooltip?: string;
    } => {
      const isInvalid =
        upsId !== currentUpsId ||
        !(startDate <= sessionEndDate && endDate >= sessionStartDate);

      if (tariffType === 'SUBSCRIPTION') {
        return {
          icon: 'faCalendarDaysSolid',
          color: isInvalid ? 'error' : 'primary',
        };
      }

      if (tariffType === 'OFFSTREET') {
        return { icon: 'faSquareParkingSolid', color: 'primary' };
      }

      if (tariffType === 'SHORT_TERM') {
        return {
          icon: 'faClockRegular',
          color: isInvalid ? 'error' : 'default',
        };
      }

      if (tariffType === 'FINE') {
        if (status === 'CANCELLED') {
          return {
            icon: 'faCircleXmarkSolid',
            color: 'error',
            tooltip: t(cancellationReason),
          };
        } else if (status === 'CORRECTION' || !!claimReason) {
          return { icon: 'faCarOnSolid', color: 'warn' };
          //* INITIAL
        } else {
          return {
            icon: 'faClockRegular',
            color: 'primary',
            tooltip: t('CLAIM_REASON_' + claimReason),
          };
        }
      }

      return { icon: 'faCircleQuestionSolid', color: 'disabled' };
    },
    [],
  );

  const historyFormatted = useCallback(
    (history: History[]) => {
      if (!id) return [];

      return history?.map(
        ({
          tariffType,
          startDate,
          endDate,
          usedMeansOfPayment,
          upsId,
          legacyId,
          status = null,
          cancellationReason,
          claimReason,
        }: History) => {
          const _id = uuidV4();
          const iconLeft = historyIconLeft({ tariffType, usedMeansOfPayment });
          const date = dayjs(startDate).format('DD/MM/YY HH:mm');
          const upsName = upsId ? t(`ups-${upsId}-name`) : null;
          const upsCode = legacyId?.[0] || '?';
          const iconRight = historyIconRight({
            tariffType,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            upsId,
            currentUpsId: id,
            sessionStartDate: new Date(readSession?.sessionStartDate),
            sessionEndDate: new Date(readSession?.sessionEndDate),
            status,
            claimReason,
            cancellationReason,
          });
          const duration = dayjs
            .duration(
              new Date(endDate).getTime() - new Date(startDate).getTime(),
            )
            .format('HH:mm');

          return {
            _id,
            pointOfSale: (
              <Icon
                config={{ type: iconLeft, color: 'primary', size: 'large' }}
              ></Icon>
            ),
            ups: (
              <Col config={{ vertical: 'center' }}>
                <Write
                  data={{
                    item: upsName ? `${upsCode} - ${upsName}` : `${upsCode}`,
                  }}
                  config={{
                    mode: 'namise',
                  }}
                ></Write>
                <Write
                  data={{ item: date }}
                  config={{
                    mode: 'namise-description',
                  }}
                ></Write>
              </Col>
            ),
            duration: (
              <div className="historyDuration">
                {cancellationReason && (
                  <Popover
                    config={{ trigger: 'hover', placement: 'bottom' }}
                    data={<Write data={{ item: cancellationReason }} />}
                  >
                    <Icon
                      className="historyDurationIcon"
                      config={{
                        type: iconRight?.icon,
                        color: iconRight?.color,
                        size: 'xsmall',
                      }}
                    />
                  </Popover>
                )}

                {!cancellationReason && claimReason && (
                  <Popover
                    config={{ trigger: 'hover', placement: 'bottom' }}
                    data={<Write data={{ item: t(claimReason) }} />}
                  >
                    <Icon
                      className="historyDurationIcon"
                      config={{
                        type: iconRight?.icon,
                        color: iconRight?.color,
                        size: 'xsmall',
                      }}
                    />
                  </Popover>
                )}

                {!cancellationReason && !claimReason && (
                  <Icon
                    className="historyDurationIcon"
                    config={{
                      type: iconRight?.icon,
                      color: iconRight?.color,
                      size: 'xsmall',
                    }}
                  />
                )}

                <Write
                  data={{ item: duration }}
                  config={{ mode: 'value-medium' }}
                ></Write>
              </div>
            ),
          };
        },
      );
    },
    [lang, id, readSession],
  );

  const intrepretUncoveredDurationColor = useCallback(
    (duration: number) => {
      const findUps = me?.ups?.find((ups: any) => ups?._id === id);

      const outrageTreshold = findUps?.config?.prestoScan?.outrageTreshold;

      if (!(typeof duration === 'number') || !findUps || !outrageTreshold)
        return 'basic';

      delete outrageTreshold?.__typename;

      const interpret: any = {
        danger: 'error',
        warning: 'warn',
        valid: 'success',
      };

      const formattedOutrageTreshold = Object.entries(outrageTreshold)
        .sort((a: any, b: any) => b[1] - a[1])
        .find(([key, value]: any) => duration >= value);
      if (!formattedOutrageTreshold) return 'basic';

      const [label] = formattedOutrageTreshold;
      return interpret?.[label] || 'basic';
    },
    [me, id],
  );

  const formattedTerminalState = useCallback(
    (alarms: any[]) => {
      return (
        <ul className="horodatorInfoList">
          {alarms.map(({ startDate, endDate, terminalId, name }, index) => {
            const translatedText = t('HORODATOR_LINE_ALARM')
              .replace(/[\<\>]/g, '')
              .replace(
                '[startDate]',
                `${
                  startDate ? dayjs(startDate).format('DD/MM/YY HH:mm') : '***'
                }`,
              )
              .replace(
                '[endDate]',
                `${endDate ? dayjs(endDate).format('DD/MM/YY HH:mm') : '***'}`,
              )
              .replace('[terminalId]', `${terminalId}`)
              .replace('[description]', `<strong>${name}</strong>`);

            return (
              <li
                key={`horodator-info-item-${index}`}
                dangerouslySetInnerHTML={{
                  __html: translatedText,
                }}
              ></li>
            );
          })}
        </ul>
      );
    },
    [lang, id],
  );

  const loadSessions = ({
    upsId,
    getParamsUrl,
  }: {
    getParamsUrl: Object;
    upsId?: string;
  }) => {
    if (id) {
      const searchRequest = {
        limit: 50,
        ...getParamsUrl,
        upsId,
      };

      if (
        'minUncoveredDuration' in searchRequest &&
        !searchRequest?.minUncoveredDuration
      )
        delete searchRequest.minUncoveredDuration;

      dispatch('SEARCH_SESSIONS', searchRequest);
    }
  };

  useEffect(() => {
    if (!GlobalStore.socket) return;

    // Sessions appears in vao only when updated as TO_CONTROL
    //GlobalStore.socket.on('sessions:created', loadSessions);

    GlobalStore.socket.on('sessions:updated', () => {
      loadSessions({ upsId: id, getParamsUrl });
    });

    return () => {
      //GlobalStore.socket?.off('sessions:created');
      GlobalStore.socket?.off('sessions:updated');
    };
  }, [GlobalStore.socket, id, getParamsUrl]);

  useEffect(() => {
    loadSessions({ upsId: id, getParamsUrl });

    return () => dispatch('RESET_CURRENT_SESSION', {});
  }, [getParamsUrl, id, me, updateSession]);

  const handleSessionTime = useCallback(
    (text: string) => text.replace('[d]', t('DAY_FIRST_LETTER')),
    [lang],
  );

  return (
    <Page className="vao">
      <Zone
        config={{
          gap: { x: 2 },
          zones: [['vao', 'infraction']],
          rows: ['1fr'],
          columns: ['minmax(400px, 18vw)', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'vao' }}>
          <Widget.Group
            config={{
              icon: 'faCameraSolid',
              title: `${t('vao')} (${searchSessions?.paging?.count || 0} ${t(
                searchSessions?.paging?.count &&
                  searchSessions?.paging?.count > 1
                  ? 'sessions'
                  : 'session',
              ).toLowerCase()})`,
            }}
          >
            <Zone
              config={{
                zones: [['list']],
                rows: ['1fr'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area config={{ area: 'list' }}>
                <Widget>
                  <Zone
                    config={{
                      gap: {
                        y: 2,
                      },
                      zones: [['filter'], ['table']],
                      rows: ['min-content', '1fr'],
                      columns: ['1fr'],
                    }}
                  >
                    <Zone.Area config={{ area: 'filter' }}>
                      <Widget>
                        <Form
                          data={{
                            value: {
                              ...getParamsUrl,
                              ignoreTerminalState:
                                !!getParamsUrl?.ignoreTerminalState,
                            },
                          }}
                          handleEvent={{
                            change: (data: any) => {
                              if (!data?.minUncoveredDuration) {
                                removeParamsUrl('minUncoveredDuration');
                                delete data?.minUncoveredDuration;
                                delete getParamsUrl?.minUncoveredDuration;
                                localStorage.removeItem('minUncoveredDuration');
                              } else {
                                localStorage.setItem(
                                  'minUncoveredDuration',
                                  data?.minUncoveredDuration,
                                );
                              }

                              setParamsUrl({
                                ...getParamsUrl,
                                page: 1,
                                ...data,
                              });

                              dispatch('RESET_CURRENT_SESSION', {});
                            },
                          }}
                        >
                          <Row
                            config={{
                              horizontal: 'spaceBetween',
                              vertical: 'end',
                            }}
                          >
                            <Form.Item
                              config={{
                                name: 'minUncoveredDuration',
                                label: t('durationOfDefaultOfRights'),
                                way: 'vertical',
                                rules: [],
                              }}
                            >
                              <DurationPicker
                                config={{
                                  format: 'HH:mm',
                                  width: 'full',
                                  clear: true,
                                  presets: [
                                    {
                                      label: `30 ${t('minutes').toLowerCase()}`,
                                      value: 1000 * 60 * 30,
                                    },
                                    {
                                      label: `1 ${t('hour').toLowerCase()}`,
                                      value: 1000 * 60 * 60,
                                    },
                                    {
                                      label: `2 ${t('hours').toLowerCase()}`,
                                      value: 1000 * 60 * 60 * 2,
                                    },
                                    {
                                      label: `4 ${t('hours').toLowerCase()}`,
                                      value: 1000 * 60 * 60 * 4,
                                    },
                                  ],
                                }}
                              />
                            </Form.Item>
                          </Row>
                          <Space />
                          <Row
                            config={{
                              horizontal: 'start',
                              vertical: 'center',
                            }}
                          >
                            <Form.Item
                              config={{
                                name: 'ignoreTerminalState',
                                label: t('ignoreTerminalState'),
                                way: 'horizontal',
                                rules: [],
                                tooltip: t('ignoreTerminalStateTooltip'),
                              }}
                            >
                              <Checkbox />
                            </Form.Item>
                          </Row>
                        </Form>
                      </Widget>
                    </Zone.Area>

                    <Zone.Area config={{ area: 'table' }}>
                      <Widget>
                        <Table<any>
                          handleEvent={{
                            read: ({ _id }) => {
                              dispatch('READ_SESSION', { id: _id });
                            },
                            paging: (value: any) => {
                              const searchRequest = {
                                upsId: id,
                                ...getParamsUrl,
                                ...value,
                              };

                              if (
                                'minUncoveredDuration' in searchRequest &&
                                !searchRequest?.minUncoveredDuration
                              )
                                delete searchRequest.minUncoveredDuration;

                              dispatch('SEARCH_SESSIONS', searchRequest);
                            },
                          }}
                          data={
                            searchSessions
                              ? {
                                  list: searchSessions?.list?.map(
                                    ({
                                      uncoveredDuration: { text, icon, time },
                                      previousClaimsForUser,
                                      ...rest
                                    }: any) => ({
                                      ...rest,
                                      rapo: previousClaimsForUser ? (
                                        <div></div>
                                      ) : (
                                        ''
                                      ),
                                      uncoveredDuration: (
                                        <Badge
                                          config={{
                                            size: 'medium',
                                            text: handleSessionTime(text),
                                            icon,
                                            color:
                                              intrepretUncoveredDurationColor(
                                                time,
                                              ),
                                          }}
                                        ></Badge>
                                      ),
                                    }),
                                  ),
                                  paging: searchSessions.paging,
                                }
                              : undefined
                          }
                          config={{
                            alternateColor: true,
                            actions: { read: true },
                            pagination: 'scroll',
                            columns: [
                              {
                                title: '',
                                key: 'rapo',
                              },
                              {
                                title: t('endOfSession'),
                                key: 'endDate',
                              },
                              {
                                title: t('defaultOfRights'),
                                key: 'uncoveredDuration',
                              },
                            ],
                            testId: 'vao-table',
                          }}
                        />
                      </Widget>
                    </Zone.Area>
                  </Zone>
                </Widget>
              </Zone.Area>
            </Zone>
          </Widget.Group>
        </Zone.Area>

        <Zone.Area config={{ area: 'infraction' }}>
          {readSession ? (
            <Widget.Group
              config={{
                title: t('infringementReport'),
                extra: (
                  <>
                    {readSession?.lpn && (
                      <div className="descriptionItem vaoPlate">
                        <Icon
                          className="descriptionItemIcon"
                          config={{
                            type: 'faCarSolid',
                            color: 'primary',
                            size: 'small',
                          }}
                        ></Icon>

                        <div className="vaoPlateDetails">
                          <Write
                            data={{ item: readSession.lpn }}
                            config={{
                              mode: 'link',
                              color: 'primary',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                ),
              }}
            >
              <div className="vaoVerbalise">
                <Button
                  handleEvent={{
                    click: () => {
                      dispatch('SHOW_WIZARD', {
                        id: 'createFps',
                        store: {
                          fps: readSession.fps,
                        },
                      });
                    },
                  }}
                  config={{
                    disabled: !isVerbalizationAllowed,
                    color: 'error',
                    weight: 'bold',
                    text: t('IVerbalize'),
                    size: 'large',
                    testId: 'createFpsBtn',
                  }}
                />
                <Space config={{ way: 'horizontal' }} />
                <Button
                  handleEvent={{
                    click: () =>
                      dispatch('UPDATE_SESSION', {
                        sessionId: readSession._id,
                        input: {
                          controlState: 'NOT_FINED',
                        },
                      }),
                  }}
                  config={{
                    disabled: !isVerbalizationAllowed,
                    size: 'large',
                    mode: 'stroke',
                    weight: 'bold',
                    text: t('IDontVerbalize'),
                    color: 'primary',
                  }}
                />
              </div>

              <Zone
                config={{
                  zones: [
                    ['description', 'description'],
                    ['cameraEnter', 'cameraExit'],
                    ['more', 'more'],
                  ],
                  rows: ['min-content', '1fr', 'min-content'],
                  columns: ['1fr', '1fr'],
                }}
              >
                <Zone.Area config={{ area: 'description' }}>
                  <Widget>
                    <Space config={{ count: 1, way: 'vertical' }} />
                    {readSession?.forDes && (
                      <Description
                        config={{ way: 'vertical' }}
                        data={[
                          {
                            key: 'entry',
                            label: t('entry'),
                            value: (
                              <Write
                                data={{
                                  item: readSession?.forDes?.entry?.textItem,
                                }}
                                config={{
                                  mode: 'value-large',
                                }}
                              />
                            ),
                          },
                          {
                            key: 'totalDuration',
                            label: t('totalDuration'),
                            value: (
                              <div className="descriptionItem">
                                <Icon
                                  className="descriptionItemIcon"
                                  config={{
                                    type: 'faClockRegular',
                                    color: 'disabled',
                                    size: 'xsmall',
                                  }}
                                ></Icon>
                                <Write
                                  data={{
                                    item: readSession?.forDes?.totalDuration?.textItem?.replace(
                                      '[d]',
                                      t('DAY_FIRST_LETTER'),
                                    ),
                                  }}
                                  config={{
                                    mode: 'value-large',
                                  }}
                                />
                              </div>
                            ),
                          },
                          {
                            key: 'ofWhichRight',
                            label: t('ofWhichRight'),
                            value: (
                              <div className="descriptionItem">
                                <Icon
                                  className="descriptionItemIcon"
                                  config={{
                                    type: 'faSquareCheckSolid',
                                    color: 'success',
                                    size: 'xsmall',
                                  }}
                                ></Icon>
                                <Write
                                  data={{
                                    item: readSession?.forDes?.rightDuration?.textItem.replace(
                                      '[d]',
                                      t('DAY_FIRST_LETTER'),
                                    ),
                                  }}
                                  config={{
                                    mode: 'value-large',
                                  }}
                                />
                              </div>
                            ),
                          },
                          {
                            key: 'defaultOfRights',
                            label: t('defaultOfRights'),
                            value: (
                              <div className="descriptionItem">
                                <Icon
                                  className="descriptionItemIcon"
                                  config={{
                                    type: 'faClockRotateLeftSolid',
                                    size: 'xsmall',
                                    color: intrepretUncoveredDurationColor(
                                      readSession?.forDes?.defaultRight
                                        ?.iconColor,
                                    ),
                                  }}
                                ></Icon>
                                <Write
                                  data={{
                                    item: readSession?.forDes?.defaultRight?.textItem.replace(
                                      '[d]',
                                      t('DAY_FIRST_LETTER'),
                                    ),
                                  }}
                                  config={{
                                    mode: 'value-large',
                                  }}
                                />
                              </div>
                            ),
                          },
                          {
                            key: 'parkingMeter',
                            label: t('parkingMeter'),
                            value:
                              readSession?.forDes?.horodator &&
                              readSession?.forDes?.horodator?.length > 0 ? (
                                <Popover
                                  config={{
                                    trigger: 'hover',
                                    placement: 'bottom',
                                  }}
                                  data={
                                    <div className="horodatorInfo">
                                      <span className="horodatorInfoTitle">
                                        {t('HORODATOR_TITLE_ALARM')}
                                      </span>
                                      {formattedTerminalState(
                                        readSession?.forDes?.horodator,
                                      )}
                                    </div>
                                  }
                                >
                                  <div className="descriptionItem">
                                    <Icon
                                      className="descriptionItemIcon"
                                      config={{
                                        type: 'faTriangleExclamationSolid',
                                        color: 'warn',
                                        size: 'large',
                                      }}
                                    ></Icon>
                                    <Write
                                      data={{
                                        item: t('problem'),
                                      }}
                                      config={{
                                        mode: 'value-large',
                                      }}
                                    />
                                  </div>
                                </Popover>
                              ) : (
                                <div className="descriptionItem">
                                  <Icon
                                    className="descriptionItemIcon"
                                    config={{
                                      type: 'faHeartPulseSolid',
                                      color: 'success',
                                      size: 'xsmall',
                                    }}
                                  ></Icon>
                                  <Write
                                    data={{
                                      item: 'OK',
                                    }}
                                    config={{
                                      mode: 'value-large',
                                    }}
                                  />
                                </div>
                              ),
                          },
                          {
                            key: 'exit',
                            label: t('exit'),
                            value: (
                              <Write
                                data={{
                                  item: readSession?.forDes?.exit?.textItem,
                                }}
                                config={{
                                  mode: 'value-large',
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    )}
                  </Widget>
                </Zone.Area>

                <Zone.Area config={{ area: 'cameraEnter' }}>
                  <Widget>
                    <Image
                      data={{
                        src: readSession?.camera?.entry,
                      }}
                      config={{
                        size: 'full',
                        preview: true,
                      }}
                    />
                    <Badge
                      className="vaoBadge"
                      config={{
                        size: 'xlarge',
                        radius: false,
                        text: t(`entry`),
                        icon: 'faArrowRightToBracketSolid',
                        color: 'success',
                      }}
                    ></Badge>
                  </Widget>
                </Zone.Area>

                <Zone.Area config={{ area: 'cameraExit' }}>
                  <Widget>
                    <Image
                      data={{
                        src: readSession?.camera?.exit,
                      }}
                      config={{
                        size: 'full',
                        preview: true,
                      }}
                    />
                    <Badge
                      className="vaoBadge"
                      config={{
                        size: 'xlarge',
                        radius: false,
                        text: t(`exit`),
                        icon: 'faArrowRightFromBracketSolid',
                        color: 'warn',
                      }}
                    ></Badge>
                  </Widget>
                </Zone.Area>

                <Zone.Area config={{ area: 'more' }}>
                  <Widget>
                    <Zone
                      config={{
                        zones: [['cover', 'history']],
                        rows: [`31vh`],
                        columns: ['2fr', '1fr'],
                      }}
                    >
                      <Zone.Area config={{ area: 'cover' }}>
                        <Widget config={{ title: t('coverage') }}>
                          <Cover data={readSession?.forCover} />
                        </Widget>
                      </Zone.Area>

                      <Zone.Area config={{ area: 'history' }}>
                        {/* <Space config={{ way: 'vertical' }} /> */}

                        <Widget
                          config={{
                            title: `${t('history')} (${
                              readSession?.history?.length || 0
                            })`,
                          }}
                        >
                          <Table<any>
                            handleEvent={{
                              paging: (value) => {
                                dispatch('SEARCH_SESSIONS', {
                                  upsId: id,
                                  ...getParamsUrl,
                                  ...value,
                                });
                              },
                            }}
                            data={{
                              list: historyFormatted(readSession?.history),
                              paging: { limit: 1, current: 1, count: 1 },
                            }}
                            config={{
                              alternateColor: true,
                              pagination: 'scroll',
                              columns: [
                                {
                                  title: '',
                                  key: 'pointOfSale',
                                },
                                {
                                  title: `${t('parking')} / ${t('date')}`,
                                  key: 'ups',
                                },
                                {
                                  title: `${t('duration')}`,
                                  key: 'duration',
                                },
                              ],
                            }}
                          />
                        </Widget>
                      </Zone.Area>
                    </Zone>
                  </Widget>
                </Zone.Area>
              </Zone>
            </Widget.Group>
          ) : (
            <Widget.Group
              config={{
                title: t('infringementReport'),
              }}
            >
              <div className="vaoNotSelectedSession">
                <Empty
                  config={{
                    mode: { name: 'select', text: `${t('selectSession')} ...` },
                  }}
                />
              </div>
            </Widget.Group>
          )}
        </Zone.Area>
      </Zone>
    </Page>
  );
});
